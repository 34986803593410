import { Card } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactElement[];
};

/**
 * This can used to give an intro inside a <Card>
 */
const IntroCard: React.FunctionComponent<Props> = (props): JSX.Element => {
  return (
    <Card
      sx={{
        padding: '25px',
        paddingTop: '0',
        marginBottom: '25px',
      }}
    >
      {props.children}
    </Card>
  );
};

export default IntroCard;
