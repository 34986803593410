import { CommonServerSideParams } from '@/app/types/CommonServerSideParams';
import IntroCard from '@/common/components/IntroCard';
import MiniDrawer from '@/common/components/menu/MiniDrawer';
import { OnlyBrowserPageProps } from '@/layouts/core/types/OnlyBrowserPageProps';
import { SSGPageProps } from '@/layouts/core/types/SSGPageProps';
import PublicLayout from '@/layouts/public/components/PublicLayout';
import { getPublicLayoutStaticPaths, getPublicLayoutStaticProps } from '@/layouts/public/publicLayoutSSG';
import { AMPLITUDE_PAGES } from '@/modules/core/amplitude/events';
import { createLogger } from '@/modules/core/logging/logger';
import { Container, Typography } from '@mui/material';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import React, { useState } from 'react';

const fileLabel = 'pages/[locale]/public/index';
const logger = createLogger({
  // eslint-disable-line no-unused-vars,@typescript-eslint/no-unused-vars
  fileLabel,
});

/**
 * Only executed on the server side at build time.
 * Necessary when a page has dynamic routes and uses "getStaticProps".
 */
export const getStaticPaths: GetStaticPaths<CommonServerSideParams> = getPublicLayoutStaticPaths();

/**
 * Only executed on the server side at build time.
 *
 * @return Props (as "SSGPageProps") that will be passed to the Page component, as props.
 *
 * @see https://github.com/vercel/next.js/discussions/10949#discussioncomment-6884
 * @see https://nextjs.org/docs/basic-features/data-fetching#getstaticprops-static-generation
 */
export const getStaticProps: GetStaticProps<SSGPageProps, CommonServerSideParams> = getPublicLayoutStaticProps();

/**
 * SSG pages are first rendered by the server (during static bundling).
 * Then, they're rendered by the client, and gain additional props (defined in OnlyBrowserPageProps).
 * Because this last case is the most common (server bundle only happens during development stage), we consider it a default.
 * To represent this behaviour, we use the native Partial TS keyword to make all OnlyBrowserPageProps optional.
 *
 * Beware props in OnlyBrowserPageProps are not available on the server.
 */
type Props = {} & SSGPageProps<Partial<OnlyBrowserPageProps>>;

/**
 * Main public page.
 */
const MainPublicPage: NextPage<Props> = (props): JSX.Element => {
  const [error] = useState(null);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <PublicLayout
        {...props}
        pageName={AMPLITUDE_PAGES.TEMPLATE_SSG_PAGE}
        headProps={{
          seoTitle: 'TTT CryptoScore',
          seoDescription:
            'The dashboard page displays cryptos with an opiniated score from our Crypto team, based on data from Coingecko and from Coinmarketcap.',
        }}
      >
        <Container>
          <MiniDrawer />
          <IntroCard>
            <h1>Welcome to the TTT CryptoScore</h1>
            <Typography>
              <strong>The CryptoScore</strong> is an application provided by <strong>The Trading Team</strong>.
            </Typography>
            <Typography>
              Behind the scene, it uses the data of <strong>several public APIs</strong> and gathers them in a meaningful way to you, crypto traders.
            </Typography>
          </IntroCard>
        </Container>
        <Container>
          <h1>Changelog</h1>
          <h2>Version 5.0.239 - 02/07/2022</h2>
          <h3>Fixes</h3>
          <ul>
            <li>Fixed Chainlink Ecosystem analysis</li>
          </ul>
          <h1>Changelog</h1>
          <h2>Version 5.0.237 - 05/05/2022</h2>
          <h3>Features</h3>
          <ul>
            <li>Renamed Cryto Dashboard to CryptoScore</li>
          </ul>
          <h2>Version 5.0.230 - 29/04/2022</h2>
          <h3>Features</h3>
          <ul>
            <li>2 new screens: Defillama TVL per chain or protocol!</li>
          </ul>
          <h2>Version 5.0.226 - 08/03/2022</h2>
          <h3>Features</h3>
          <ul>
            <li>Dashboard: Show real Twitter followers</li>
            <li>Dashboard: Remove Defipulse</li>
          </ul>
          <h1>Changelog</h1>
          <h2>Version 5.0.225 - 29/01/2022</h2>
          <h3>Fixes</h3>
          <ul>
            <li>SEO optimization: titles and descriptions</li>
          </ul>
          <h2>Version 5.0.221 - 27/01/2022</h2>
          <h3>Features</h3>
          <ul>
            <li>Dashboard + top 10: Twitter followers count is now accurate</li>
            <li>Dashboard + top 10: review of the score algorithm (split between fundamental and timing scores)</li>
          </ul>
          <h2>Version 5.0.219 - 17/01/2022</h2>
          <h3>Features</h3>
          <ul>
            <li>Dashboard: remove Insurance, add Metaverse</li>
            <li>Dashboard: add public notice</li>
            <li>Chainlink ecosystem: add a voting system to remove false positives</li>
          </ul>
          <h2>Version 5.0.215 - 20/12/2021</h2>
          <h3>Features</h3>
          <ul>
            <li>Master IDs: display the matching reasons</li>
          </ul>
          <h2>Version 5.0.212 - 13/12/2021</h2>
          <h3>Features</h3>
          <ul>
            <li>Coingecko categories: add quick filters by market cap</li>
          </ul>
          <h2>Version 5.0.208 - 10/12/2021</h2>
          <h3>Features</h3>
          <ul>
            <li>Dashboard: add quick filters by market cap</li>
            <li>Dashboard top 10 (with quick filters by market cap)</li>
          </ul>
          <h2>Version 5.0.202 - 09/12/2021</h2>
          <h3>Features</h3>
          <ul>
            <li>Dashboard: add a total score (sum of CG and CMC scores)</li>
            <li>Dashboard: each crypto has now a direct link to CG</li>
          </ul>
          <h3>Bugfixes</h3>
          <ul>
            <li>Dashboard and Chainlink: allow to sort while filtering columns without resetting the whole table</li>
          </ul>
          <h3>Bugfixes</h3>
          <ul>
            <li>Configure Google analytics</li>
          </ul>
          <h2>Version 5.0.201 - 05/12/2021</h2>
          <ul>
            <li>Initial public version</li>
          </ul>
        </Container>
      </PublicLayout>
    );
  }
};

export default MainPublicPage;
