import FingerprintIcon from '@mui/icons-material/Fingerprint';
import Label from '@mui/icons-material/Label';
import LabelImportant from '@mui/icons-material/LabelImportant';
import AddLinkIcon from '@mui/icons-material/AddLink';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import Dashboard from '@mui/icons-material/Dashboard';
import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { NextRouter } from 'next/router';
import React from 'react';

type Props = {
  router: NextRouter;
  locale: string;
};

/**
 * List items to be used inside a <Drawer>
 */
const ListItems: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { router, locale } = props;
  return (
    <List>
      <div title="Home">
        <ListItem button key="Home" onClick={() => router.push(`/${locale}`)} selected={router.pathname === '/[locale]'}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      </div>
      <div title="Ranks">
        <ListItem button key="Ranks" onClick={() => router.push(`/${locale}/ranks`)} selected={router.pathname === '/[locale]/ranks'}>
          <ListItemIcon>
            <ShowChartIcon />
          </ListItemIcon>
          <ListItemText primary="Ranks" />
        </ListItem>
      </div>
      <div title="Chainlink">
        <ListItem button key="Chainlink" onClick={() => router.push(`/${locale}/chainlink`)} selected={router.pathname === '/[locale]/chainlink'}>
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText primary="Chainlink" />
        </ListItem>
      </div>
      <div title="Master IDs">
        <ListItem button key="Ids" onClick={() => router.push(`/${locale}/ids`)} selected={router.pathname === '/[locale]/ids'}>
          <ListItemIcon>
            <FingerprintIcon />
          </ListItemIcon>
          <ListItemText primary="Master IDs" />
        </ListItem>
      </div>
      <div title="Dashboard">
        <ListItem button key="Dashboard" onClick={() => router.push(`/${locale}/dashboard`)} selected={router.pathname === '/[locale]/dashboard'}>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </div>
      <div title="Dashboard top 10">
        <ListItem
          button
          key="Dashboard top 10"
          onClick={() => router.push(`/${locale}/dashboard/top10`)}
          selected={router.pathname === '/[locale]/dashboard/top10'}
        >
          <ListItemIcon>
            <MilitaryTechIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard top 10" />
        </ListItem>
      </div>
      <div title="Coingecko categories">
        <ListItem button key="Categories" onClick={() => router.push(`/${locale}/categories`)} selected={router.pathname === '/[locale]/categories'}>
          <ListItemIcon>
            <Label />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItem>
      </div>
      <div title="Coinmarketcap tags">
        <ListItem button key="Tags" onClick={() => router.push(`/${locale}/tags`)} selected={router.pathname === '/[locale]/tags'}>
          <ListItemIcon>
            <LabelImportant />
          </ListItemIcon>
          <ListItemText primary="Tags" />
        </ListItem>
      </div>
      <div title="Defillama chains">
        <ListItem button key="Tags" onClick={() => router.push(`/${locale}/tvl/chains`)} selected={router.pathname === '/[locale]/tvl/chains'}>
          <ListItemIcon>
            <AddLinkIcon />
          </ListItemIcon>
          <ListItemText primary="Defillama chains" />
        </ListItem>
      </div>
      <div title="Defillama protocols">
        <ListItem button key="Tags" onClick={() => router.push(`/${locale}/tvl/protocols`)} selected={router.pathname === '/[locale]/tvl/protocols'}>
          <ListItemIcon>
            <AddLocationIcon />
          </ListItemIcon>
          <ListItemText primary="Defillama protocols" />
        </ListItem>
      </div>
    </List>
  );
};

export default ListItems;
